img.showLoader{
    display: none;
}

img:not(.showLoader) + .Loader{
    display: none !important;
}

*{
    user-select: none;
}