img.showLoader {
    display: none;
}

img:not(.showLoader)+.Loader {
    display: none !important;
}

.tracker-img:hover .overlay {
    opacity: 1;
}

.tracker-img .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 7px;
    color: white;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    transition: opacity 0.5s;
}

.tracker-img .overlay.deleted .a-delete {
    display: none !important;
}

.tracker-img .overlay.deleted {
    opacity: 1;
    justify-content: center;
    align-items: center;
    background-color: #0d1116d5;
}

.tracker-img .overlay:not(.deleted) {
    justify-content: flex-end;
    align-items: start;
}

.tracker-img .overlay:not(.deleted) h6 {
    display: none;
}

button:has(span.material-icons),
button:has(span.material-symbols-outlined),
a:has(span.material-icons) {
    display: flex;
    justify-content: center;
    align-items: center;
}